import {Component, OnDestroy, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {FsxSnackbarComponent, FsxSnackbarTypeEnum, IconsService} from 'fsx-component-library';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { Subject, takeUntil, tap} from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorsStateService } from './services/ui-state/errors-state/errors-state.service';
import { SuccessStateService } from './services/ui-state/success-state/success-state.service';
import { WarningStateService } from './services/ui-state/warning-state/warning-state.service';
import { GenericStateService } from './services/ui-state/generic-state/generic-state.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports:[
    RouterOutlet,
    MatSnackBarModule
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  protected readonly FsxSnackbarEnum = FsxSnackbarTypeEnum;

  title = 'PA Environmental Hearing Board';

  private destroy$: Subject<unknown> = new Subject();

  constructor(private readonly domSanitizer: DomSanitizer,
              private readonly iconService: IconsService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly errorsStateService: ErrorsStateService,
              private readonly successStateService: SuccessStateService,
              private readonly warningStateService: WarningStateService,
              private readonly genericStateService: GenericStateService,
              private readonly snackBar: MatSnackBar) {

    this.errorsStateService.errorMessage$
    .pipe(
      takeUntil(this.destroy$),
      tap(error => {
        this.snackBar.openFromComponent(FsxSnackbarComponent, {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: { message: error, snackType: FsxSnackbarTypeEnum.Error},
          panelClass: [FsxSnackbarTypeEnum.Error]
        });
      })
    )
    .subscribe()
    ;

    this.successStateService.successMessage$
    .pipe(
      takeUntil(this.destroy$),
      tap(message => {
        this.snackBar.openFromComponent(FsxSnackbarComponent, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: { message: message, snackType: FsxSnackbarTypeEnum.Success},
          panelClass: [FsxSnackbarTypeEnum.Success]
        });
      })
    )
    .subscribe()
    ;

    this.warningStateService.warningMessage$
    .pipe(
      takeUntil(this.destroy$),
      tap(message => {
        this.snackBar.openFromComponent(FsxSnackbarComponent, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: { message: message, snackType: FsxSnackbarTypeEnum.Warning},
          panelClass: [FsxSnackbarTypeEnum.Warning]
        });
      })
    )
    .subscribe()
    ;

    this.genericStateService.genericMessage$
    .pipe(
      takeUntil(this.destroy$),
      tap(message => {
        this.snackBar.openFromComponent(FsxSnackbarComponent, {
          // duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: { message: message, snackType: FsxSnackbarTypeEnum.Default},
          panelClass: [FsxSnackbarTypeEnum.Default]
        });
      })
    )
    .subscribe()
    ;
  }

  /**
   * OnInit Lifecycle Hook
   */
  ngOnInit() {
    // Register Icons from standalone library.
    this.iconService.registerIcons();
    this.matIconRegistry.addSvgIcon(
      'list',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/list_default.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'document_intake',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/document_intake.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/cancel.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'clear_default',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/clear_default.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'docket_search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/docket_search.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'envelope',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/envelope.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'case-preview',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/case-preview.svg',
      )
    );
    this.matIconRegistry.addSvgIcon(
      'back_arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/back_arrow.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'x-twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/x-twitter.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/youtube.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/home_filled.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/phone_filled.svg',
      ),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
