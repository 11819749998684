export interface IUserEndpoints {
    emailLog: (userId: string) => string;
    resendWelcomeEmail: (userId: string) => string;
    createUser: () => string;
    userInfo: (userId: string) => string;
    updateUser: () => string;
    newUsers: () => string;
    matchingUsers: (userId: string) => string;
    rejectUser: () => string;
    approveUser: () => string;
    activateUser: () => string;
    deActivateUser: () => string;
    deleteUser: () => string;
    userList: () => string;
    userAcceptTerms: () => string;
    connections: (userId?: string) => string;
    batchConnections: () => string;
    patchPrimaryEmail: () => string;
    email: (emailLogId: string) => string;
}

export const userEndpoints = (versionedUrl: string): IUserEndpoints => {
    const baseUrl = `${versionedUrl}api/User`;

    return {
        emailLog: (userId: string) => `${baseUrl}/EmailLog/${userId}`,
        resendWelcomeEmail: (userId: string) => `${baseUrl}/ResendWelcomeEmail/${userId}`,
        createUser: () => `${baseUrl}/CreateUser`,
        userInfo: (userId: string) => `${baseUrl}/UserInfo/${userId}`,
        updateUser: () => `${baseUrl}/UpdateUser`,
        newUsers: () => `${baseUrl}/NewUsers`,
        matchingUsers: (userId: string) => `${baseUrl}/MatchingUsers/${userId}`,
        rejectUser: () => `${baseUrl}/RejectUser`,
        approveUser: () => `${baseUrl}/ApproveUser`,
        activateUser: () => `${baseUrl}/ActivateUser`,
        deActivateUser: () => `${baseUrl}/DeActivateUser/`,
        deleteUser: () => `${baseUrl}/DeleteUser`,
        userList: () => `${baseUrl}/UserList`,
        userAcceptTerms: () => `${baseUrl}/AcceptTerms`,
        connections: (userId?: string) => !!userId ? `${baseUrl}/Connections/${userId}` : `${baseUrl}/Connections`,
        batchConnections: () => `${baseUrl}/Connections/Batch`,
        patchPrimaryEmail: () => `${baseUrl}/PatchPrimaryEmail`,
        email: (emailLogId: string) => `${versionedUrl}api/Email/${emailLogId}`
    }
}
